import React, { ReactNode, useMemo } from "react"
import { styled } from "../../../../../stitches.config"

import BackgroundInnEmptyGrayLight from "../../../../../redesign/assets/innpolandRedesign/footer-inn-empty-gray-light.svg"
import BackgroundInnEmptyGray from "../../../../../redesign/assets/innpolandRedesign/footer-inn-empty-gray.svg"
import BackgroundInnGray from "../../../../../redesign/assets/innpolandRedesign/footer-inn-gray.svg"
import { ReactComponent as LogoTheme } from '../../../../../redesign/assets/innpolandRedesign/footer-inn-theme.svg?react';

interface FooterBackgroundProps {
    isDarkMode?: boolean;
}
const FooterBackground: React.FC<FooterBackgroundProps> = ({ isDarkMode }) => {
    return (
        <Background>
            <Sign>
                {isDarkMode ?
                    <>
                        <img className="desktop" src={BackgroundInnGray} />
                        <img className="mobile" src={BackgroundInnEmptyGrayLight} />
                    </>
                    :
                    <>
                        <div className="desktop">
                            <LogoTheme />
                        </div>
                        <img className="mobile" src={BackgroundInnEmptyGray} />
                    </>
                }
            </Sign>
        </Background>
    )
};

const Background = styled('div', {
    position: "absolute",
    right: 0,
    top: 0,
    transform: "rotate(-90deg)",

    "@bp4": {
        bottom: 0,
        top: "auto",
        transform: "rotate(0deg)",
    },

    "& .mobile": {
        "@bp4": {
            display: "none",
        },
    },
    "& .desktop": {
        display: "none",
        "@bp4": {
            display: "block",
        },
    },
})

const Sign = styled('div', {
    img: {
        width: "280px",
        position: "relative",
        top: "50px",
        right: "222px",
        "@media (min-width: 480px)": {
            right: "207px",
        },
        "@bp4": {
            top: "auto",
            width: "220px",
            bottom: "44px",
            right: "60px",
        },
        "@bp5": {
            width: "240px",
            right: "80px",
        },
        "@media(min-width: 1480px)": {
            width: "320px",
        },
    },
    svg: {
        position: "relative",
        "@bp4": {
            position: "relative",
            top: "auto",
            width: "220px",
            bottom: "37px",
            right: "60px",
        },
        "@bp5": {
            width: "320px",
            right: "80px",
        },
        ".st0inn": {
            fill: "$primary",
        }
    }
})

export default FooterBackground;